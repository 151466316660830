import { Button, Typography } from "@material-ui/core";
import { CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { addPrompt, getPrompt } from "../services";
import SnackBarComponent from "./layout/SnackBarComponent";

const CustomPrompt = () => {
  const [customPrompt, setCustomPrompt] = useState({
    custom_system_prompt: "",
    custom_assistant_prompt: "",
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [showSnack, setShowSnack] = useState({ state: false });

  const fetchPrompt = async () => {
    const response = await getPrompt();
    if (response.data.success) {
      const responseData = response.data.data;
      console.log("response.data.data :", response.data.data);
      setCustomPrompt(responseData);
    }
  };

  useEffect(() => {
    fetchPrompt();
  }, []);

  const handlePromptUpdate = async () => {
    console.log("customPrompt in update :", customPrompt);
    if (
      customPrompt?.custom_assistant_prompt?.length &&
      customPrompt?.custom_system_prompt?.length
    ) {
      setIsUpdating(true);
      const response = await addPrompt(customPrompt);
      if (response.data.success) {
        setIsUpdating(false);
        setCustomPrompt({
          custom_system_prompt: "",
          custom_assistant_prompt: "",
        });
        setShowSnack({ state: true, message: response.data.message });
        fetchPrompt();
      }
    }
  };

  const handlePromptChange = (event) => {
    const { value, name } = event.target;
    setCustomPrompt((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="w-full md:p-12 p-2 min-h-[93.5vh] border-l-[0.03rem] border-solid flex flex-col space-y-6">
      <Typography color="primary" variant="h5">
        Add your custom prompt
      </Typography>
      <TextField
        label="What would you like Chatgpt to know about you to provide better responses ?"
        name="custom_system_prompt"
        multiline
        rows={8}
        fullWidth
        value={customPrompt.custom_system_prompt}
        onChange={handlePromptChange}
        helperText="Use variable {history} variable if you want last history in your prompt."
      />
      <TextField
        name="custom_assistant_prompt"
        multiline
        rows={8}
        fullWidth
        value={customPrompt.custom_assistant_prompt}
        onChange={handlePromptChange}
        helperText="Use variable {history} variable if you want last history in your prompt."
      />
      <Button
        color="primary"
        variant="outlined"
        onClick={handlePromptUpdate}
        className="w-1/4 self-end"
      >
        {isUpdating ? <CircularProgress /> : "Save"}
      </Button>
      {showSnack.state && <SnackBarComponent message={showSnack?.message} />}
    </div>
  );
};

export default CustomPrompt;

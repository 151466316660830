import { ThemeProvider } from "@material-ui/core";
import { Assignment, Description } from "@material-ui/icons";
import simpleRestProvider from "ra-data-simple-rest";
import { React } from "react";
import { Admin, Authenticated, CustomRoutes, Resource } from "react-admin";
import { Route } from "react-router-dom";
import theme from "../themeConfig";
import AdminAssessmentList from "./AdminAssessmentList";
import { AuthProvider } from "./AuthProvider";
import EditAssessment from "./EditAssessment";
import Questions from "./Questions";
import AdminLayout from "./layout/AdminLayout";
import CustomPrompt from "./CustomPrompt";

const AdminComponent = () => {
  const dataProvider = simpleRestProvider(
    `${process.env.REACT_APP_BASE_API_URL}`
  );
  return (
    <ThemeProvider theme={theme}>
      <Admin
        authProvider={AuthProvider}
        dataProvider={dataProvider}
        layout={AdminLayout}
        theme={theme}
      >
        <CustomRoutes>
          <Route
            path="assessment/create"
            element={
              <Authenticated>
                <Questions />
              </Authenticated>
            }
          />

          <Route
            path="edit/:slug"
            exact
            element={
              <Authenticated>
                <EditAssessment />
              </Authenticated>
            }
          />
        </CustomRoutes>

        <Resource
          name="/assessment/all"
          options={{ label: "Assessments" }}
          icon={Assignment}
          list={
            <Authenticated>
              <AdminAssessmentList />
            </Authenticated>
          }
        />
        <Resource
          name="/custom_prompt"
          options={{ label: "Custom Prompt" }}
          icon={Description}
          list={
            <Authenticated>
              <CustomPrompt />
            </Authenticated>
          }
        />
      </Admin>
    </ThemeProvider>
  );
};

export default AdminComponent;

import * as React from "react";
import { AppBar } from "react-admin";

const AdminAppBar = (props) => {
  return (
    <AppBar
      sx={{
        color: "white",
        "& .RaAppBar-title": {
          flex: 1,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },
      }}
      className="bg-gradient-to-r from-primary from-40% to-primaryLight text-paper-main shadow-md "
    >
      <div className="w-full ml-8 flex items-center justify-center">
        <img
          src="/assets/logo/logo.svg"
          alt="The Mind Sync"
          style={{ height: "2rem", color: "white" }}
          className="grayscale brightness-200 contrast-200 saturate-200 hue-rotate-180"
        />
        {/* <div className="text-lg font-bold">Admin</div> */}
        {/* <div /> */}
      </div>
    </AppBar>
  );
};

export default AdminAppBar;

import axiosInstance from "./service/axios.instance";

export const createAssessment = async (
  assessmentTitle,
  assessmentIdentifier,
  assessmentSlug,
  status,
  questions,
  results,
  mediaFile
) => {
  return axiosInstance.post("/assessment/create-assessment", {
    assessment_title: assessmentTitle,
    assessment_identifier: assessmentIdentifier,
    assessment_slug: assessmentSlug,
    questions,
    status,
    results,
    doctors_video: mediaFile.doctors_video,
    assessment_icon: mediaFile.icon,
  });
};

export const fetchAssessments = async () => {
  return axiosInstance.get("/assessment/assessments/admin");
};

export const fetchAssessment = async (assessmentSlug) => {
  return axiosInstance.get(`/assessment/${assessmentSlug}/admin`);
};

export const deleteAssessment = async (assessmentId) => {
  return axiosInstance.put(
    `/assessment/change-assessment-status/${assessmentId}`,
    {
      status: "DELETED",
    }
  );
};

export const updateAssessment = async (assessmentId, updateAssessmentJson) => {
  return axiosInstance.put(
    `/assessment/update/${assessmentId}`,
    updateAssessmentJson
  );
};

export const markAsActive = async (assessmentId, status) => {
  return axiosInstance.put(
    `/assessment/change-assessment-status/${assessmentId}`,
    {
      status: status,
    }
  );
};

export const uploadFile = async (file, type) => {
  const fileUploadFormData = new FormData();
  fileUploadFormData.append(`video`, file);
  return axiosInstance.post(
    `${process.env.REACT_APP_BASE_API_URL}/assessment/upload-file/${type}`,
    fileUploadFormData
  );
};

export const checkSlug = async (slug) => {
  return axiosInstance.get(`/assessment/check-slug/${slug}`);
};

export const addPrompt = (customPrompt) => {
  return axiosInstance.post("/custom-prompt/create-prompt/", customPrompt);
};

export const getPrompt = () => {
  return axiosInstance.get("/custom-prompt/get-prompt/");
};

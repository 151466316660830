import { React, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Card, CardContent, FormHelperText } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { CloudUpload, Delete, Replay } from "@material-ui/icons";
import {
  CardHeader,
  CardMedia,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import { uploadFile } from "../services";

const Result = ({
  index,
  result,
  removeResult,
  handleResultValueChange,
  resultsError,
}) => {
  const [isMediaFileUploadLoading, setIsMediaFileUploadLoading] = useState({
    emoji: false,
    doctors_video: false,
  });
  const [mediaFile, setMediaFile] = useState({ emoji: "", doctors_video: "" });

  useEffect(() => {
    if (result.emoji)
      setMediaFile((prev) => ({ ...prev, emoji: result.emoji }));
    if (result.doctors_video)
      setMediaFile((prev) => ({
        ...prev,
        doctors_video: result.doctors_video,
      }));
  }, [result.emoji]);

  const handleChangeFile = (event, fileType) => {
    switch (fileType) {
      case "emoji":
        setIsMediaFileUploadLoading((prev) => ({ ...prev, emoji: true }));
        uploadFile(event.target.files[0], "image")
          .then((response) => {
            if (response.data.success) {
              setMediaFile((prev) => ({
                ...prev,
                emoji: response.data.url,
              }));
              handleResultValueChange("emoji", response.data.url, index);
            }
          })
          .catch(() => handleResultValueChange("emoji", null, index))
          .finally(() => {
            setIsMediaFileUploadLoading(false);
          });
        break;
      case "video":
        setIsMediaFileUploadLoading((prev) => ({
          ...prev,
          doctors_video: true,
        }));
        setMediaFile((prev) => ({
          ...prev,
          doctors_video: null,
        }));
        uploadFile(event.target.files[0], "video")
          .then((response) => {
            if (response.data.success) {
              const uploadedFile = {
                mimeType: response.data.data.mimetype,
                fileName: response.data.data.originalname,
                s3Url: response.data.url,
              };
              setMediaFile((prev) => ({
                ...prev,
                doctors_video: uploadedFile,
              }));
              handleResultValueChange("doctors_video", uploadedFile, index);
            }
          })
          .catch(() => handleResultValueChange("doctors_video", null, index))
          .finally(() => {
            setIsMediaFileUploadLoading((prev) => ({
              ...prev,
              doctors_video: false,
            }));
          });
        break;

      default:
        break;
    }
  };

  return (
    <Card className="w-full pb-8" elevation={2} variant="elevation">
      <CardHeader
        title={
          <div className="flex items-center w-full justify-between">
            Result {index + 1}
            <Button
              variant="contained"
              color="error"
              startIcon={<Delete />}
              onClick={(event) => removeResult(index)}
            >
              Remove Result
            </Button>
          </div>
        }
        className="bg-paper-border w-full md:px-12 p-4 pb-12"
      />
      <CardContent className="flex flex-col space-y-6 items-start w-full ">
        <div className="flex items-start w-full justify-between space-x-4">
          <TextField
            required
            className="w-1/4"
            label="Score more than"
            type="number"
            InputProps={{ inputProps: { min: 0, max: 100 } }}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              handleResultValueChange("more_than", e.target.value, index);
            }}
            id="outlined-basic"
            value={result.more_than}
            variant="outlined"
            error={resultsError[index]?.more_than}
          />
          <TextField
            required
            fullWidth
            onChange={(e) => {
              handleResultValueChange("comment", e.target.value, index);
            }}
            id="outlined-basic"
            value={result.comment}
            label="Comment"
            variant="outlined"
            error={resultsError[index]?.comment}
            helperText={
              resultsError[index]?.comment ? "Comment can't be empty" : null
            }
          />
        </div>
        <Editor
          apiKey={process.env.REACT_APP_TINY_MCE_KEY}
          id={`result_${index}`}
          value={result.answer}
          label="Type the question..."
          required
          onEditorChange={(content) => {
            handleResultValueChange("answer", content, index);
          }}
          init={{
            height: 300,
            width: "100%",
            plugins: [
              "a11ychecker",
              "advlist",
              "advcode",
              "advtable",
              "autolink",
              "checklist",
              "export",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "powerpaste",
              "fullscreen",
              "formatpainter",
              "insertdatetime",
              "media",
              "table",
              "help",
              "wordcount",
            ],
            toolbar:
              // eslint-disable-next-line no-multi-str
              "undo redo | casechange blocks | bold italic backcolor | \
               alignleft aligncenter alignright alignjustify | \
               bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
          }}
        />
        {resultsError[index]?.answer && (
          <div className="mt-2 ml-4">
            <FormHelperText error={resultsError[index]?.answer}>
              Answer can't be empty and limit cannot exceed 1024 words
            </FormHelperText>
          </div>
        )}
        <div className="flex items-start justify-between w-full">
          <div className="icon w-full flex flex-col items-center space-y-4 justify-center">
            <div className="flex flex-col items-start space-y-1">
              <div className="text-sm text-textColor-secondary">Emoji</div>
              <label>
                <input
                  accept="image/x-png,image/jpeg"
                  className="hidden"
                  type="file"
                  onChange={(event) => handleChangeFile(event, "emoji")}
                />
                <Button
                  disabled={isMediaFileUploadLoading.emoji}
                  component="span"
                  title="Upload"
                  variant="outlined"
                  startIcon={
                    !isMediaFileUploadLoading.emoji ? (
                      mediaFile.emoji.length ? (
                        <Replay />
                      ) : (
                        <CloudUpload />
                      )
                    ) : (
                      <CircularProgress size="1rem" />
                    )
                  }
                  sx={{ paddingX: 10 }}
                >
                  {mediaFile?.emoji?.length ? "Change Emoji" : " Upload Emoji"}
                </Button>
              </label>
              {resultsError[index]?.emoji && (
                <FormHelperText error={resultsError[index]?.emoji}>
                  * Emoji is required
                </FormHelperText>
              )}
            </div>
            {!!mediaFile?.emoji?.length && (
              <Card sx={{ borderRadius: "100%" }}>
                <CardMedia
                  sx={{ width: "5rem" }}
                  component="img"
                  image={mediaFile?.emoji}
                />
              </Card>
            )}
          </div>
          <Divider flexItem orientation="vertical" />
          <div className="doctors-video  w-full flex flex-col items-center space-y-4 justify-center">
            <div className="flex flex-col items-start space-y-1">
              <div className="text-sm text-textColor-secondary">
                Doctor's Video
              </div>
              <label>
                <input
                  accept="video/*"
                  className="hidden"
                  type="file"
                  onChange={(event) => handleChangeFile(event, "video")}
                />
                <Button
                  disabled={isMediaFileUploadLoading.doctors_video}
                  component="span"
                  title="Upload"
                  variant="outlined"
                  startIcon={
                    !isMediaFileUploadLoading.doctors_video ? (
                      mediaFile.doctors_video ? (
                        <Replay />
                      ) : (
                        <CloudUpload />
                      )
                    ) : (
                      <CircularProgress size="1rem" />
                    )
                  }
                  sx={{ paddingX: 10 }}
                >
                  {mediaFile?.doctors_video ? "Change Video" : " Upload Video"}
                </Button>
              </label>
              {resultsError[index]?.doctors_video && (
                <FormHelperText error={resultsError[index].doctors_video}>
                  * Video is required
                </FormHelperText>
              )}
            </div>

            {mediaFile.doctors_video?.s3Url?.length && (
              <Card className="max-w-[20rem]">
                <CardMedia
                  component="video"
                  controls
                  src={mediaFile.doctors_video?.s3Url}
                />
              </Card>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default Result;

import { Add, CloudUpload, Replay } from "@material-ui/icons";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  Alert,
  Card,
  CardMedia,
  CircularProgress,
  Divider,
  FormHelperText,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  checkSlug,
  createAssessment,
  updateAssessment,
  uploadFile,
} from "../services";
import Question from "./Question";
import Result from "./Result";
import SnackBarComponent from "./layout/SnackBarComponent";
import { validation } from "./validations/validation";
import axiosInstance from "../service/axios.instance";

const Questions = (props) => {
  let navigate = useNavigate();

  const { singleAssessment } = props;

  const questionObject = {
    title: "",
    options: [
      {
        optionTitle: "",
        weight: 0,
      },
      {
        optionTitle: "",
        weight: 0,
      },
      {
        optionTitle: "",
        weight: 0,
      },
    ],
  };

  const ques = [questionObject];
  const [errorState, setErrorState] = useState([]);
  const [questions, setQuestions] = useState(ques);
  const [results, setResults] = useState([
    {
      more_than: 0,
      comment: "",
      answer: "",
      emoji: "",
    },
  ]);

  const [resultsError, setResultsError] = useState([]);

  const [assessmentTitle, setAssessmentTitle] = useState("");
  const [assessmentTitleError, setAssessmentTitleError] = useState(false);
  const [mediaFileError, setMediaFileError] = useState(false);
  const [assessmentIdentifier, setAssessmentIdentifier] = useState("");
  const [assessmentIdentifierError, setAssessmentIdentifierError] =
    useState(false);
  const [assessmentSlug, setAssessmentSlug] = useState("");
  const [assessmentSlugError, setAssessmentSlugError] = useState(false);

  const [status, setStatus] = useState("ACTIVE");
  const [message, setMessage] = useState("");

  const [mainTitle, setMainTitle] = useState("Create");

  const [mediaFile, setMediaFile] = useState({
    icon: null,
    doctors_video: null,
  });

  const [isMediaFileUploadLoading, setIsMediaFileUploadLoading] = useState({
    doc_video: false,
    icon: false,
  });

  const statusDropDown = [
    {
      label: "Active",
      value: "ACTIVE",
    },
    {
      label: "Inactive",
      value: "INACTIVE",
    },
  ];

  useEffect(() => {
    if (singleAssessment) {
      setQuestions(singleAssessment.questions);
      setAssessmentTitle(singleAssessment.assessment_title);
      setAssessmentIdentifier(singleAssessment.assessment_identifier);
      setAssessmentSlug(singleAssessment.assessment_slug);
      setStatus(singleAssessment.status);
      setResults(singleAssessment.results);
      setMainTitle("Edit");
      setMediaFile({
        doctors_video: singleAssessment.doctors_video,
        icon: singleAssessment.assessment_icon,
      });
    }
  }, [singleAssessment]);

  const AssessmentTitleChangeHandler = (event) => {
    setAssessmentTitle(event.target.value);
  };

  const AssessmentIdentifierChangeHandler = (event) => {
    setAssessmentIdentifier(event.target.value);
  };

  const AssessmentSlugChangeHandler = (event) => {
    setAssessmentSlug(event.target.value);
  };

  const handleSlugInputKeyPress = (event) => {
    if (event.key === "Tab" && !assessmentSlug.length) {
      event.preventDefault();
      setAssessmentSlug(`${assessmentTitle.toLowerCase()}-test`);
    }
  };

  const handleUpdateAssessment = async () => {
    try {
      await updateAssessment(singleAssessment._id, {
        questions: questions,
        assessment_title: assessmentTitle,
        assessment_identifier: assessmentIdentifier,
        assessment_slug: assessmentSlug,
        status: status,
        results,
        doctors_video: mediaFile.doctors_video,
        assessment_icon: mediaFile.icon,
      });
      setMessage("Assessment updated successfully");
    } catch (error) {
      console.log(error);
      setMessage("Error, assessment cannot updated successfully");
    }

    navigate("/assessment/all");
  };

  const handleCreateAssessment = async () => {
    console.log("inside create ");
    try {
      await createAssessment(
        assessmentTitle,
        assessmentIdentifier,
        assessmentSlug,
        status,
        questions,
        results,
        mediaFile
      );
      setMessage("Assessment created successfully");
    } catch (error) {
      console.log(error);
      setMessage("Error, cannot create assessment");
    }
    navigate("/assessment/all");
  };

  const statusChangeHandler = (event) => {
    setStatus(event.target.value);
  };

  const assessmentSubmitHandler = async (event) => {
    const validationResult = validation(
      assessmentTitle,
      assessmentIdentifier,
      assessmentSlug,
      questions,
      results,
      mediaFile
    );
    if (
      validationResult.isQuizError ||
      validationResult.isDoctorsVideoError ||
      validationResult.isIconError
    ) {
      setErrorState(validationResult.questions);
      setAssessmentTitleError(validationResult.isTitleError);
      setAssessmentIdentifierError(validationResult.isIdentifierError);
      setAssessmentSlugError(validationResult.isSlugError);
      setResultsError(validationResult.resultsError);
      setMediaFileError({
        icon: validationResult.isIconError,
        doctors_video: validationResult.isDoctorsVideoError,
      });
    } else {
      singleAssessment ? handleUpdateAssessment() : handleCreateAssessment();
    }
  };

  const optionsChangeHandler = (
    optionValue,
    optionIndex,
    questionIndex,
    weight
  ) => {
    const dummyQuesOptionsArr = [...questions];
    if (optionValue || optionValue === "") {
      dummyQuesOptionsArr[questionIndex].options.splice(optionIndex, 1, {
        optionTitle: optionValue,
        weight: dummyQuesOptionsArr[questionIndex].options[optionIndex].weight,
      });
    } else if (weight || weight === "") {
      dummyQuesOptionsArr[questionIndex].options.splice(optionIndex, 1, {
        optionTitle:
          dummyQuesOptionsArr[questionIndex].options[optionIndex].optionTitle,
        weight,
      });
    }

    setQuestions(dummyQuesOptionsArr);
  };

  const addOption = (event, optionIndex, questionIndex) => {
    const dummyAddQuesOptionsArr = [...questions];
    if (dummyAddQuesOptionsArr[questionIndex].options.length < 9) {
      dummyAddQuesOptionsArr[questionIndex].options.splice(optionIndex + 1, 0, {
        optionTitle: "",
        weight: 0,
      });
    }

    setQuestions(dummyAddQuesOptionsArr);
  };

  const removeOption = (event, optionIndex, questionIndex) => {
    if (
      (optionIndex === 0 || optionIndex === 1) &&
      questions[questionIndex].options.length <= 2
    ) {
      console.log("You cannot remove the first 3  option");
    } else {
      const dummyRemoveQuesOptionsArr = [...questions];
      dummyRemoveQuesOptionsArr[questionIndex].options.splice(optionIndex, 1);
      setQuestions(dummyRemoveQuesOptionsArr);
    }
  };

  const addResult = () => {
    const updatedResults = [
      ...results,
      {
        more_than: 0,
        comment: "",
        answer: "",
        emoji: "",
      },
    ];
    setResults(updatedResults);
  };

  const removeResult = (resultIndex) => {
    if (results.length === 1) return;
    const updatedResults = [...results];
    updatedResults.splice(resultIndex, 1);
    setResults(updatedResults);
  };

  const handleResultValueChange = (name, value, index) => {
    const updatedResults = [...results];
    updatedResults[index][name] = value;
    setResults(updatedResults);
  };

  const questionTitleChangeHandler = (value, indexVal) => {
    console.log(questions, indexVal);
    const dummyQuestionsArr = [...questions];
    dummyQuestionsArr[indexVal].title = value;
    setQuestions(dummyQuestionsArr);
  };

  const addQuestion = (event, indexValue) => {
    const dummyAddArr = [...questions];
    dummyAddArr.splice(indexValue + 1, 0, questionObject);
    setQuestions(dummyAddArr);
  };

  const cloneQuestion = (event, indexValue) => {
    const dummyAddArr = [...questions];
    const dummyArr = {
      ...questions[indexValue],
      options: [...questions[indexValue].options],
    };

    dummyAddArr.splice(indexValue + 1, 0, dummyArr);
    setQuestions(dummyAddArr);
  };

  const removeQuestion = (event, indexValue) => {
    if (indexValue === 0 && questions.length <= 1) {
      console.log("You cannot remove the first Question");
    } else {
      console.log("You are to remove ");
      const dummyRemoveArr = [...questions];
      dummyRemoveArr.splice(indexValue, 1);
      setQuestions(dummyRemoveArr);
    }
  };

  function handleChangeFile(event, fileType) {
    switch (fileType) {
      case "icon":
        setIsMediaFileUploadLoading((prev) => ({ ...prev, icon: true }));
        uploadFile(event.target.files[0], "image")
          .then((response) => {
            if (response.data.success) {
              // const uploadedFile = {
              //   mimeType: response.data.data.mimetype,
              //   fileName: response.data.data.originalname,
              //   s3Url: response.data.url,
              // };
              setMediaFile((prev) => ({ ...prev, icon: response.data.url }));
            }
          })
          .catch(() =>
            setMediaFile((prev) => ({ ...prev, doctors_video: null }))
          )
          .finally(() => {
            setIsMediaFileUploadLoading((prev) => ({
              ...prev,
              icon: false,
            }));
          });
        break;
      case "video":
        setIsMediaFileUploadLoading((prev) => ({ ...prev, doc_video: true }));
        uploadFile(event.target.files[0], "video")
          .then((response) => {
            if (response.data.success) {
              const uploadedFile = {
                mimeType: response.data.data.mimetype,
                fileName: response.data.data.originalname,
                s3Url: response.data.url,
              };
              setMediaFile((prev) => ({
                ...prev,
                doctors_video: uploadedFile,
              }));
            }
          })
          .finally(() => {
            setIsMediaFileUploadLoading((prev) => ({
              ...prev,
              doc_video: false,
            }));
          });
        break;

      default:
        break;
    }
  }

  const [isUniqueSlug, setIsUniqueSlug] = useState(true);
  const checkSlugUniqueness = async () => {
    if (assessmentSlug) {
      try {
        const response = await checkSlug(assessmentSlug);
        setIsUniqueSlug(response.data.isUnique);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className="flex flex-col items-start w-full px-4 space-y-8 pb-12 border-l-[0.03rem]">
      <span className="text-primary text-4xl font-bold my-12">
        {`${mainTitle} Assessment`}
      </span>
      {message && <SnackBarComponent message={message} />}

      <div className="flex flex-col items-start space-y-6 w-full">
        <TextField
          className="w-1/2"
          id="outlined-basic"
          label="Assessment Name"
          value={assessmentTitle}
          variant="outlined"
          error={assessmentTitleError}
          helperText={assessmentTitleError && "Assessment name is required"}
          onChange={AssessmentTitleChangeHandler}
          required
        />

        <TextField
          required
          multiline
          rows={3}
          className="w-1/2"
          id="outlined-basic"
          label="Assessment Identifier"
          value={assessmentIdentifier}
          error={assessmentIdentifierError}
          helperText={
            assessmentIdentifierError && "Assessment name is required"
          }
          variant="outlined"
          onChange={AssessmentIdentifierChangeHandler}
        />
        <TextField
          required
          className="w-1/2"
          id="outlined-basic"
          label="Assessment Slug"
          placeholder={`${assessmentTitle.toLowerCase()}-test (press Tab to enter this value)`}
          onKeyDown={handleSlugInputKeyPress}
          value={assessmentSlug}
          onBlur={checkSlugUniqueness}
          error={assessmentSlugError || !isUniqueSlug}
          disabled={props.isEdit}
          helperText={
            isUniqueSlug
              ? assessmentSlugError
                ? "Assessment slug is required"
                : `This slug will be used to find the assestment through url ex: www.himinipro.com/assestment/${
                    assessmentSlug.length ? assessmentSlug : "anxiety-test"
                  }`
              : "Same slug found"
          }
          variant="outlined"
          onChange={AssessmentSlugChangeHandler}
        />
        <Divider flexItem />
        <div className="icon w-1/2 flex items-start justify-between">
          <div className="flex flex-col items-start space-y-2">
            <div className="text-sm text-textColor-secondary">
              Assesment Icon
            </div>
            <label>
              <input
                accept="image/*"
                className="hidden"
                type="file"
                onChange={(event) => handleChangeFile(event, "icon")}
              />
              <Button
                disabled={isMediaFileUploadLoading.icon}
                component="span"
                title="Upload"
                variant="outlined"
                startIcon={
                  !isMediaFileUploadLoading.icon ? (
                    mediaFile.icon?.length ? (
                      <Replay />
                    ) : (
                      <CloudUpload />
                    )
                  ) : (
                    <CircularProgress size="1rem" />
                  )
                }
                sx={{ paddingX: 10 }}
              >
                {mediaFile.icon?.length ? "Change Image" : " Upload Image"}
              </Button>
            </label>
            {mediaFileError.icon && (
              <FormHelperText error={mediaFileError.icon}>
                * Assesment Icon is required
              </FormHelperText>
            )}
          </div>
          {mediaFile.icon?.length && (
            <Card sx={{ borderRadius: "100%" }}>
              <CardMedia
                sx={{ width: "5rem" }}
                component="img"
                image={mediaFile.icon}
              />
            </Card>
          )}
        </div>
        <div className="doctors-video w-1/2 flex items-center justify-between">
          <div className="flex flex-col items-start space-y-2">
            <div className="text-sm text-textColor-secondary">
              Doctor's Video
            </div>
            <label>
              <input
                accept="video/*"
                className="hidden"
                type="file"
                onChange={(event) => handleChangeFile(event, "video")}
              />
              <Button
                disabled={isMediaFileUploadLoading.doc_video}
                component="span"
                title="Upload"
                variant="outlined"
                startIcon={
                  !isMediaFileUploadLoading.doc_video ? (
                    mediaFile.doctors_video ? (
                      <Replay />
                    ) : (
                      <CloudUpload />
                    )
                  ) : (
                    <CircularProgress size="1rem" />
                  )
                }
                sx={{ paddingX: 10 }}
              >
                {mediaFile.doctors_video ? "Change Video" : " Upload Video"}
              </Button>
            </label>
            {mediaFileError.doctors_video && (
              <FormHelperText error={mediaFileError.doctors_video}>
                * Video is required
              </FormHelperText>
            )}
          </div>

          {mediaFile.doctors_video?.s3Url?.length && (
            <Card className="max-w-[20rem]">
              <CardMedia
                component="video"
                controls
                src={mediaFile.doctors_video?.s3Url}
              />
            </Card>
          )}
        </div>
      </div>

      <Alert severity="info" className="w-full flex items-center">
        <Typography>
          Below questions must have same number of options and with same
          weights.
        </Typography>
      </Alert>

      {questions.map((singleQuestion, index) => {
        return (
          <div className="relative w-full pb-12">
            <Question
              key={`questions-${index}`}
              options={singleQuestion.options}
              questionError={errorState[index]}
              addOption={addOption}
              removeOption={removeOption}
              addQuestion={addQuestion}
              removeQuestion={removeQuestion}
              cloneQuestion={cloneQuestion}
              questionTitleChangeHandler={questionTitleChangeHandler}
              optionsChangeHandler={optionsChangeHandler}
              index={index}
              titleValue={singleQuestion.title}
            />

            <div className="absolute bottom-7 left-[43%]">
              <Button
                startIcon={<Add />}
                size="large"
                fullWidth
                variant="contained"
                onClick={(event) => addQuestion(event, index)}
              >
                Add Question
              </Button>
            </div>
          </div>
        );
      })}
      <Divider flexItem />

      <Alert severity="info" className="w-full flex items-center">
        <Typography>
          Assign result's 'Score More Than' field value in decending{" "}
          <ArrowDownwardIcon fontSize="small" /> order only!
        </Typography>
      </Alert>
      {results.map((result, index) => {
        return (
          <div className="relative w-full pb-8">
            <Result
              index={index}
              result={result}
              addResult={addResult}
              removeResult={removeResult}
              handleResultValueChange={handleResultValueChange}
              resultsError={resultsError}
            />
            <div className="absolute bottom-3 left-[44%]">
              <Button
                startIcon={<Add />}
                size="large"
                fullWidth
                variant="contained"
                onClick={() => addResult()}
              >
                Add Result
              </Button>
            </div>
          </div>
        );
      })}
      <Divider flexItem />
      <div className="mt-4 flex items-center justify-between space-y-4 w-full">
        <TextField
          required
          className="w-3/12"
          id="select status"
          select
          label="Status"
          value={status}
          onChange={statusChangeHandler}
        >
          {statusDropDown.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </TextField>
        <Button
          className="w-3/12"
          size="large"
          variant="contained"
          color="secondary"
          onClick={assessmentSubmitHandler}
          sx={{ color: "white" }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default Questions;

import { Add, Delete } from "@material-ui/icons";
import { Button, CardContent, CardHeader, FormHelperText } from "@mui/material";
import Card from "@mui/material/Card";
import { Editor } from "@tinymce/tinymce-react";
import { React } from "react";
import "../App.css";
import Option from "./Option";

const Question = (props) => {
  const {
    questionTitleChangeHandler,
    removeQuestion,
    cloneQuestion,
    questionError,
    optionsChangeHandler,
    addOption,
    removeOption,
    index,
    titleValue,
    options,
  } = props;

  return (
    <Card className="w-full pb-8" variant="elevation" elevation={3}>
      <CardHeader
        className="w-full bg-paper-border"
        title={
          <div className="flex items-center w-full justify-between">
            Question {index + 1}
            <div className="flex gap-2">
              <Button
                className="w-1/10"
                startIcon={<Delete />}
                color="primary"
                variant="contained"
                onClick={(event) => cloneQuestion(event, index)}
              >
                Clone Question
              </Button>
              <Button
                className="w-1/10"
                startIcon={<Delete />}
                color="error"
                variant="contained"
                disabled={index === 0}
                onClick={(event) => removeQuestion(event, index)}
              >
                Remove Question
              </Button>
            </div>
          </div>
        }
      />
      <CardContent className="flex flex-col md:px-12 p-4">
        <Editor
          apiKey={process.env.REACT_APP_TINY_MCE_KEY}
          id={`question_${index}`}
          required
          value={titleValue}
          onEditorChange={(content) =>
            questionTitleChangeHandler(content, index)
          }
          init={{
            height: 300,
            plugins: [
              "a11ychecker",
              "advlist",
              "advcode",
              "advtable",
              "autolink",
              "checklist",
              "export",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "powerpaste",
              "fullscreen",
              "formatpainter",
              "insertdatetime",
              "media",
              "table",
              "help",
              "wordcount",
            ],
            toolbar:
              // eslint-disable-next-line no-multi-str
              "undo redo | casechange blocks | bold italic backcolor | \
               alignleft aligncenter alignright alignjustify | \
               bullist numlist checklist outdent indent | removeformat | a11ycheck code table help",
          }}
        />
        {questionError?.questionTitleError && (
          <div className="mt-2 ml-4">
            <FormHelperText error={questionError?.questionTitleError}>
              Question can't be empty and limit cannot exceed 1024 words
            </FormHelperText>
          </div>
        )}

        <div className="flex flex-col py-5 w-1/2 ">
          {options.map((singleOption, optionIndex) => {
            return (
              <div className="flex flex-col items-center space-y-4">
                <Option
                  key={`option-${optionIndex}`}
                  optionChangeHandler={optionsChangeHandler}
                  optionError={
                    questionError
                      ? questionError.options[optionIndex]
                      : undefined
                  }
                  addOption={addOption}
                  removeOption={removeOption}
                  questionError={questionError}
                  options={options}
                  value={singleOption.optionTitle}
                  weight={singleOption.weight}
                  optionIndex={optionIndex}
                  questionIndex={index}
                />
                <Button
                  startIcon={<Add />}
                  size="medium"
                  variant="text"
                  onClick={(event) => addOption(event, optionIndex, index)}
                >
                  Add Option
                </Button>
              </div>
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
};

export default Question;

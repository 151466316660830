import { Delete } from "@material-ui/icons";
import { IconButton, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";

const Option = (props) => {
  const {
    optionChangeHandler,
    optionError,
    removeOption,
    value,
    optionIndex,
    questionIndex,
    weight,
  } = props;

  return (
    <div className="flex items-center w-full space-x-8">
      <div className="flex items-start w-full space-x-6">
        <TextField
          className="w-full"
          id="outlined-basic"
          value={value}
          label={`Option ${optionIndex + 1}`}
          placeholder="Type the option ..."
          margin="normal"
          variant="outlined"
          onChange={(event) => {
            optionChangeHandler(
              event.target.value,
              optionIndex,
              questionIndex,
              null
            );
          }}
          error={optionError?.isOptionTitleError}
          helperText={
            optionError?.isOptionTitleError
              ? "Option can't be empty and can't exceed more than 24 words"
              : ""
          }
        />

        <TextField
          required
          className="w-2/12"
          label="Weight"
          type="number"
          margin="normal"
          inputProps={{ inputMode: "text" }}
          InputProps={{ inputProps: { min: 0, max: 100 } }}
          onWheel={(e) => e.target.blur()}
          onChange={(event) => {
            optionChangeHandler(
              null,
              optionIndex,
              questionIndex,
              event.target.value
            );
          }}
          id="outlined-basic"
          value={weight}
          variant="outlined"
          error={optionError?.isWeightError}
        />
      </div>

      <Tooltip title={`Remove Option ${optionIndex + 1}`}>
        <IconButton
          color="error"
          onClick={(event) => removeOption(event, optionIndex, questionIndex)}
          disabled={optionIndex === 0}
        >
          <Delete />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Option;

import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Questions from "./Questions";
import { fetchAssessment } from "../services";

const EditAssessment = () => {
  const params = useParams();
  const [singleAssessment, setSingleAssessment] = useState();

  useEffect(() => {
    let isMounted = true;
    if (params.slug) {
      fetchAssessment(params.slug).then((response) => {
        if (isMounted) {
          setSingleAssessment(response.data.data);
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      {singleAssessment && (
        <Questions singleAssessment={singleAssessment} isEdit={true} />
      )}
    </div>
  );
};

export default EditAssessment;

export const validation = (
  name,
  indentifier,
  slug,
  questions,
  results,
  mediaFile
) => {
  let finalObj = {};
  const questionsErrorArr = [];

  let isFinalError = false;
  let isTitleError = false;
  let isIdentifierError = false;
  let isDoctorsVideoError = false;
  let isIconError = false;
  let isSlugError = false;

  if (!name) isTitleError = true;
  if (!indentifier) isIdentifierError = true;
  if (!slug) isSlugError = true;
  if (mediaFile.doctors_video === null) isDoctorsVideoError = true;
  if (mediaFile.icon === null || !mediaFile.icon.length) isIconError = true;

  questions.forEach((question) => {
    const questionObj = {
      questionTitleError: false,
    };
    if (question.title.length === 0 || question.title.length >= 1024) {
      questionObj.questionTitleError = true;
      isFinalError = true;
    }
    const optionErrorArr = [];
    question.options.forEach((option) => {
      let error = {
        isOptionTitleError: false,
        isWeightError: false,
      };

      if (option.optionTitle.length === 0 || option.optionTitle.length >= 24) {
        error.isOptionTitleError = true;
        isFinalError = true;
      }
      if (
        option.weight === "" ||
        option.weight > 100 ||
        option.weight < 0 ||
        /^0[0-9]+$/.test(option.weight)
      ) {
        error.isWeightError = true;
        isFinalError = true;
      }
      optionErrorArr.push(error);
    });

    questionObj.options = optionErrorArr;
    questionsErrorArr.push(questionObj);
  });

  const resultsError = results.map((result) => {
    let error = {
      more_than: false,
      comment: false,
      answer: false,
      emoji: false,
      doctors_video: false,
    };

    if (result.comment.length === 0) {
      isFinalError = true;
      error.comment = true;
    }
    if (result.answer.length === 0 || result.comment.length >= 1024) {
      isFinalError = true;
      error.answer = true;
    }

    if (
      result.more_than === "" ||
      result.more_than > 100 ||
      /^0[0-9]+$/.test(result.more_than)
    ) {
      isFinalError = true;
      error.more_than = true;
    }

    if (!result.emoji.length) {
      isFinalError = true;
      error.emoji = true;
    }
    if (!result.doctors_video?.s3Url.length) {
      isFinalError = true;
      error.doctors_video = true;
    }

    return error;
  });

  finalObj = {
    questions: questionsErrorArr,
    isTitleError,
    isIdentifierError,
    isSlugError,
    isQuizError: isFinalError,
    resultsError: resultsError,
    isDoctorsVideoError,
    isIconError,
  };
  return finalObj;
};

import axios from "axios";

// Create an instance of Axios with default configuration
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL, // Replace with your API base URL
});

// Add an interceptor to attach the authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Assuming your token is stored as 'token' in localStorage

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;

import axios from "axios";

export const AuthProvider = {
  login: async ({ username, password }) => {
    const userData = await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}/auth/login`,
      {
        email: username,
        password,
      }
    );
    localStorage.setItem("token", userData.data.data.token);
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: (params) => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("token") !== "undefined"
    ) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  },
  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  getIdentity: () => Promise.resolve(),
  getPermissions: () => Promise.resolve(),
  getRoles: () => Promise.resolve(),
};

import { Add, Assignment, Delete } from "@material-ui/icons";
import EditIcon from "@mui/icons-material/Edit";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { deleteAssessment, fetchAssessments, markAsActive } from "../services";
import AlertDialog from "./layout/AlertDialog";
import { Divider } from "@material-ui/core";

const AdminAssessmentList = () => {
  const [assessments, setAssessments] = useState([]);
  const [open, setOpen] = useState(false);
  const [assessmentId, setAssessmentId] = useState();

  useEffect(() => {
    let isMounted = true;

    fetchAssessments().then((response) => {
      if (isMounted) {
        setAssessments(response.data.data);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  const activeAssessmentChangeHandler = async (assessmentId, checked) => {
    const status = checked ? "ACTIVE" : "INACTIVE";
    try {
      await markAsActive(assessmentId, status);
    } catch (error) {
      console.log(error);
    }
    fetchAssessmentsLists();
  };

  const handleClickOpen = (event, id) => {
    setOpen(true);
    setAssessmentId(id);
  };

  const handleClose = (val) => {
    setOpen(val);
  };

  const deleteHandler = async () => {
    try {
      await deleteAssessment(assessmentId);
    } catch (error) {
      console.log(error);
    }
    handleClose(false);
    fetchAssessmentsLists();
  };

  async function fetchAssessmentsLists() {
    let response;
    try {
      response = await fetchAssessments();
    } catch (err) {
      console.log(err);
    }
    setAssessments(response.data.data);
  }

  return (
    <div className="w-full md:p-12 p-2 min-h-[93.5vh] border-l-[0.03rem] border-solid">
      {open && (
        <AlertDialog
          open={open}
          closeHandler={handleClose}
          deleteHandler={deleteHandler}
        />
      )}

      <div className="mt-4 mb-4 grid ">
        <div className="justify-self-end">
          <Link to="/assessment/create">
            <Button startIcon={<Add />} variant="contained">
              Create Assessment
            </Button>
          </Link>
        </div>
      </div>

      {assessments.length ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="w-2/12">Assessment No.</TableCell>
                <TableCell align="center">Assessment Title</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
                {/* <TableCell align="center">Activity</TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {assessments.map((row, index) => (
                <React.Fragment key={index}>
                  <TableRow
                    key={row._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" key={row._id}>
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">{row.assessment_title}</TableCell>

                    <TableCell align="center">{row.status}</TableCell>

                    <TableCell align="center">
                      <div className="flex items-center justify-center space-x-2 w-full">
                        <Link to={`/edit/${row.assessment_slug}`}>
                          <Tooltip title="Edit">
                            <IconButton color="primary">
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Link>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={(event) => handleClickOpen(event, row._id)}
                            disabled={row.isActive || row.status === "deleted"}
                            color={
                              row.isActive || row.status === "deleted"
                                ? "disabled"
                                : "error"
                            }
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip
                          title={
                            row.is_active
                              ? "Change Status to Inactive"
                              : "Change Status to Active"
                          }
                        >
                          <span>
                            <Checkbox
                              icon={<ToggleOffIcon fontSize="large" />}
                              checkedIcon={<ToggleOnIcon fontSize="large" />}
                              inputProps={{ "aria-label": "controlled" }}
                              color="primary"
                              checked={row.is_active}
                              disabled={row.status === "DELETED"}
                              onChange={(event) =>
                                activeAssessmentChangeHandler(
                                  row._id,
                                  event.target.checked
                                )
                              }
                            />
                          </span>
                        </Tooltip>
                      </div>
                    </TableCell>
                    {/* <TableCell align="center">
                      <Tooltip title="Click to Active Assessment">
                        <span>
                          <Checkbox
                            icon={<ToggleOffIcon fontSize="large" />}
                            checkedIcon={<ToggleOnIcon fontSize="large" />}
                            inputProps={{ "aria-label": "controlled" }}
                            color="primary"
                            checked={row.is_active}
                            disabled={row.status === "DELETED"}
                            onChange={(event) =>
                              activeAssessmentChangeHandler(
                                row._id,
                                event.target.checked
                              )
                            }
                          />
                        </span>
                      </Tooltip>
                    </TableCell> */}
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="w-full h-[50vh] flex items-center justify-center">
          <div className="w-full text-xl text-primary text-center">
            <Assignment /> There are no assessment.
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminAssessmentList;

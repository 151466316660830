import { React, useState, useEffect } from "react";
// import Button from '@mui/material/Button';
import Snackbar from "@mui/material/Snackbar";
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';

export default function SimpleSnackbar(props) {
  const { message } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, [message]);
  //   const handleClick = () => {
  //     setOpen(true);
  //   };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  //   const action = (
  //     <Fragment>
  //       <Button color="secondary" size="small" onClick={handleClose}>
  //         UNDO
  //       </Button>
  //       <IconButton
  //         size="small"
  //         aria-label="close"
  //         color="inherit"
  //         onClick={handleClose}
  //       >
  //         <CloseIcon fontSize="small" />
  //       </IconButton>
  //     </Fragment>
  //   );

  return (
    <div>
      {/* <Button onClick={handleClick}>Open simple snackbar</Button> */}
      <Snackbar
        open={open}
        autoHideDuration={600}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleClose}
        message={message}
        // action={action}
      />
    </div>
  );
}
